<template>
  <div>
    <Navigation/>
    <!-- AI作画 -->
    <div class="bg_box">
      <div class="noneBox"></div>
      <div class="box">
        <p class="back" @click="$router.push('/aiNav')">返回</p>

        <div class="ct_box flex">
          <div class="form_upload_box">
            <ai_title ai_title="“智能Ai数字人-智能2D精品数字人"/>
            <div class="form_title_1">
              <div>智能2D精品数字人</div>
            </div>
            <div class="form_box_1">
              <!--内容录入、语音上传、选择人物-->
              <div class="form_content_1">
                <div class="form_content_1_1">
                  <p>*内容录入</p>
                  <el-input
                      placeholder="请录入数字人播报的文本，字符长度不超过 2000。"
                      v-model="form.textarea"
                      :rows="6"
                      maxlength="2000"
                      type="textarea"
                      clearable:true
                      resize="none"
                      show-word-limit
                      :disabled="form.audioFile!==''"
                  >
                  </el-input>
                </div>
                <div class="form_content_1_2">
                  <p>语音上传</p>
                  <div>
                    <el-upload
                        class="upload-demo"
                        action="/api/common/upload"
                        :headers="headers"
                        :before-upload="beforeUploadAudio"
                        :on-success="uploadAudioSuccess"
                        :on-error="uploadAudioError"
                        v-if="form.audioFile === ''"
                        style="height: 33px;overflow: hidden"
                    >
                      <el-button size="small" type="primary" plain><i class="el-icon-plus"></i>上传音频</el-button>
                    </el-upload>
                    <div v-else class="alreadyUploadAudio">
                      <div class="fileName">{{ audioFileName }}</div>
                      <i class="el-icon-error" @click="changeAudioFile"></i>
                    </div>
                  </div>

                  <div class="form_content_1_2_1">
                    <p>1.时长不超过 10 分钟</p>
                    <p>2.文件大小不超过120 MB</p>
                    <p>3.支持音频格式为wav、mp3、wma、m4a、aac</p>
                  </div>
                </div>
                <div class="form_content_1_3">
                  <div class="form_content_1_3_1">
                    <img v-if="form.figureId!==''" :src="currentDigitalHumanCharacter.pic_url"
                         style="width: 100%;height: 100%"/>
                  </div>
                  <el-button type="primary" @click="selectPerson = true">选择人物</el-button>
                </div>
              </div>

              <!--分辨率、背景图片-->
              <div class="form_content_3">
                <div class="form_content_3_1">
                  <div class="form_content_2_3">
                    <span style="margin-right: 10px">分辨率</span>
                    <el-select v-model="form.resolution" placeholder="请选择分辨率">
                      <el-option
                          v-for="item in resolutionOptions"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                      </el-option>
                    </el-select>
                  </div>

                  <div style="display: flex;align-items: center">
                    <span style="margin-right: 10px">背景图片</span>
                    <el-upload
                        class="upload-demo"
                        action="/api/common/upload"
                        :headers="headers"
                        :before-upload="beforeUploadBgPic"
                        :on-success="uploadBgPicSuccess"
                        :on-error="uploadBgPicError"
                        v-if="form.bgPicFile === ''"
                        style="height: 33px;width:200px;overflow: hidden;"
                    >
                      <el-button size="small" type="primary" style="width: 200px"
                                 :disabled="form.isBgTransparent === '1'" plain>
                        <i class="el-icon-plus"></i>上传图片
                      </el-button>
                    </el-upload>
                    <div v-else class="alreadyUploadAudio">
                      <div class="fileName">{{ bgPicFileName }}</div>
                      <i class="el-icon-error" @click="changeBgPicFile"></i>
                    </div>
                  </div>
                </div>
                <div class="form_content_3_2" style="padding-left: 305px">
                  （注：当无背景图片，背景为绿色幕布）
                </div>
              </div>
              <!--音色选择-->
              <div class="form_content_4">
                <div class="form_content_4_1">
                  <div class="form_content_4_1_1">

                    <span class="form_content_4_title">*音色选择：</span>

                    <div v-if="form.timbre!==''" class="selectedTimbre">
                      <div style="margin-right: 10px">{{ currentTimbre.nickname }}(ID:{{
                          currentTimbre.figureid
                        }})
                      </div>
                      <el-button type="primary" @click="selectTimbre = true">重新选择</el-button>
                    </div>

                    <el-button style="width: 100px;margin-right: 225px" v-else type="primary"
                               :disabled="form.audioFile!==''" @click="selectTimbre = true">
                      选择音色
                    </el-button>

                    <!--  <el-button type="primary">定制音色</el-button>-->
                    <!--  <el-button type="primary">定制形象</el-button>-->
                  </div>
                </div>
                <div class="form_content_4_2">
                  <div class="form_content_4_2_1">
                    <span class="form_content_4_title">*语速</span>
                    <el-slider
                        :min="0"
                        :max="15"
                        v-model="form.speed"
                        :step="1"
                        :disabled="form.audioFile!==''"
                    >
                    </el-slider>
                    <span style="margin-left: 10px">{{ form.speed }}</span>
                  </div>
                  <div class="form_content_4_2_2">
                    5是正常值，0-15的取值范围。越大语速越快，默认值5 （字面量需要是整数）
                  </div>
                </div>
                <div class="form_content_4_2">
                  <div class="form_content_4_2_1">
                    <span class="form_content_4_title">*音量</span>
                    <el-slider
                        :min="0"
                        :max="15"
                        v-model="form.volume"
                        :disabled="form.audioFile!==''"
                        :step="1"
                    >
                    </el-slider>
                    <span style="margin-left: 10px">{{ form.volume }}</span>
                  </div>

                  <div class="form_content_4_2_2">
                    5是正常值，0-15的取值范围，越大音量越大，默认值5 （字面量需要是整数）
                  </div>
                </div>
                <div class="form_content_4_2">
                  <div class="form_content_4_2_1">
                    <span class="form_content_4_title">*语调</span>
                    <el-slider
                        :min="0"
                        :max="15"
                        v-model="form.intonation"
                        :disabled="form.audioFile!==''"
                        :step="1"
                    >
                    </el-slider>
                    <span style="margin-left: 10px">{{ form.intonation }}</span>
                  </div>
                  <div class="form_content_4_2_2">
                    5是正常值，0-15的取值范围，越大声音越尖，默认值5 （字面量需要是整数）
                  </div>
                </div>
              </div>
              <!--数字人视频所在位置与大小-->
              <div class="form_content_5">
                <div class="form_content_5_1">
                  数字人视频所在位置与大小
                </div>
                <div class="form_content_5_2">
                  <span class="form_content_5_title">水平位置（X）</span>
                  <el-input style="width: 90px;" v-model="form.XPosition"></el-input>
                  <span style="margin-left: 10px">（范围 -0.5 到 0.5 ，取 0 居中，越大数字人越靠右）</span>
                </div>
                <div class="form_content_5_2">
                  <span class="form_content_5_title">垂直位置（Y）</span>
                  <el-input style="width: 90px;" v-model="form.YPosition"></el-input>
                  <span style="margin-left: 10px">（范围 -1 到 1 ，取 0 数字人底部对齐视频底部，越大数字人越靠上）</span>
                </div>
                <div class="form_content_5_2">
                  <span class="form_content_5_title">缩放比例（Z）</span>
                  <el-input style="width: 90px;" v-model="form.scaling"></el-input>
                  <span style="margin-left: 10px">（范围 0.5 到 1.5 ，取1原始比例，越小数字人越小）</span>
                </div>
              </div>
              <!--注意-->
              <div class="form_content_6">
                （注意：y 的取值是按数字人原始大小上下移动。
                当 z 取值大于 1，即放大数字人时，y 的值可以小于 -1 以实现更大范围下移。）
              </div>
              <!--生成-->
              <div class="result_box">
                <el-button type="primary" style="width: 500px;margin-bottom: 10px" @click="submitForm"
                           :disabled="isGenerating">
                  {{ isGenerating ? '正在生成……请耐心等待' : '生成视频' }}
                </el-button>
                <div class="result_content" v-if="result_content!==''">
                  <video controls :src="result_content"></video>
                </div>
              </div>
            </div>

          </div>
          <aiNav/>
        </div>
      </div>
    </div>
    <!--选择人物弹框-->
    <el-dialog
        title="选择人物"
        :visible.sync="selectPerson"
        width="555px"
        class="selectDialog"
    >
      <div class="selectDialog_content">
        <el-table
            :data="digitalHumanCharacterList"
            height="540"
            stripe
            style="width: 100%;">
          <el-table-column label="默认人物" header-align="center" width="100" align="center">
            <template slot-scope="scope">
              <el-checkbox v-model="scope.row.checked" :data-a="checkeds"
                           @change="chooseDigitalHuman(scope.row)"></el-checkbox>
            </template>
          </el-table-column>
          <el-table-column prop="nickname" label="昵称" width="100" header-align="center"
                           align="center"></el-table-column>
          <el-table-column prop="figureid" label="ID" width="200" header-align="center"
                           align="center"></el-table-column>
          <el-table-column prop="scene" label="形象" width="100" header-align="center" align="center">
            <template slot-scope="scope">
              <!--              <img :src="scope.row.pic_url" style="width: 70px;height: 120px"/>-->
              <el-tooltip class="item" effect="dark" content="点击预览大图" placement="right">
                <el-image
                    style="width: 80px; height: 120px"
                    :src="scope.row.scale_url"
                    :preview-src-list="[scope.row.pic_url]">
                </el-image>
              </el-tooltip>

            </template>
          </el-table-column>

        </el-table>
        <div class="selectDialog_content_bottom">
          <div class="selectedTimbre">
            <span>已选择：</span>
            <span v-if="currentDigitalHumanCharacter.figureid">{{
                currentDigitalHumanCharacter.nickname
              }}(ID:{{ currentDigitalHumanCharacter.figureid }})</span>
          </div>
          <el-button type="primary" @click="comfirmDigitalHuman">确认选择</el-button>
        </div>
      </div>

    </el-dialog>
    <!--选择音色弹框-->
    <el-dialog
        title="选择音色"
        :visible.sync="selectTimbre"
        width="1200px"
        stripe
        class="selectDialog"
    >
      <div class="selectDialog_content">
        <el-table
            :data="timbreList"
            height="540"
            stripe
            style="width: 100%;">
          <el-table-column label="状态" width="50" header-align="center" align="center">
            <template slot-scope="scope">
              <el-checkbox v-model="scope.row.checked" :data-a="checkeds"
                           @change="chooseTimbre(scope.row)"></el-checkbox>
            </template>
          </el-table-column>
          <el-table-column prop="nickname" label="音色名称" width="150" header-align="center"
                           align="center"></el-table-column>
          <el-table-column prop="figureid" label="音色ID" width="150" header-align="center"
                           align="center"></el-table-column>
          <el-table-column prop="sex" label="性别" width="50" header-align="center" align="center"></el-table-column>
          <el-table-column prop="style" label="风格" width="200" header-align="center" align="center"></el-table-column>
          <el-table-column prop="scene" label="适用场景" width="200" header-align="center"
                           align="center"></el-table-column>
          <el-table-column label="试听音频文件">
            <template slot-scope="scope">
              <audio controls :src="scope.row.audio_url"></audio>
            </template>
          </el-table-column>
        </el-table>
        <div class="selectDialog_content_bottom">
          <div class="selectedTimbre">
            <span>已选择：</span>
            <span v-if="currentTimbre.figureid">{{ currentTimbre.nickname }}(ID:{{ currentTimbre.figureid }})</span>
          </div>
          <el-button type="primary" @click="comfirmTimbre">确认选择</el-button>
        </div>
      </div>

    </el-dialog>
    <footerBot/>
  </div>
</template>

<script>
import aiNav from '@/components/aiNav.vue'
import ai_title from '@/components/ai_title.vue'
import {goAi} from "@/util/goAi";

export default {
  mounted() {
    this.getUserInfo()
    console.log('form', this.form)
    this.get3DdigitalHumans()
    this.getPublicSoundLibrary()
  },
  components: {
    aiNav,
    ai_title
  },
  watch: {
    'form.XPosition': {
      handler(newVal) {
        let num = Number(newVal)
        console.log(num)
        if (isNaN(num)) {
          this.form.XPosition = '0'
        }
      }
    },
    'form.YPosition': {
      handler(newVal) {
        let num = Number(newVal)
        console.log(num)
        if (isNaN(num)) {
          this.form.YPosition = '0'
        }
      }
    },
    'form.scaling': {
      handler(newVal) {
        let num = Number(newVal)
        console.log(num)
        if (isNaN(num)) {
          this.form.scaling = '1'
        }
      }
    }

  },
  data() {
    return {
      checkeds: false,
      headers: {
        token: '',
      },
      // 选择人物弹框状态变量
      selectPerson: false,
      // 选择音色弹框状态变量
      selectTimbre: false,
      // 音频文件名
      audioFileName: '',
      // 背景图片文件名
      bgPicFileName: '',
      // 是否开启字幕选项列表
      subtitlesOptions: [
        {
          label: '否',
          value: '0'
        },
        {
          label: '是',
          value: '1'
        },
      ],
      // 是否背景透明选项列表
      BgOptions: [
        {
          label: '否',
          value: '0'
        },
        {
          label: '是',
          value: '1'
        },
      ],
      // 分辨率选项列表
      resolutionOptions: [
        {
          label: '720x1280px(竖屏)',
          value: '720x1280'
        },
        {
          label: '1080x1440px(竖屏)',
          value: '1080x1440'
        },
        {
          label: '1080x1920px(竖屏)',
          value: '1080x1920'
        },
        {
          label: '1280x720px(横屏)',
          value: '1280x720'
        },
        {
          label: '1920x1080px(横屏)',
          value: '1920x1080'
        },
        {
          label: '2560x1440px(横屏)',
          value: '2560x1440'
        },
        {
          label: '3840x2160px(横屏)',
          value: '3840x2160'
        },
      ],
      // 音色选项列表
      timbreList: [],
      // 已选音色
      currentTimbre: {},
      // 数字人人物选项列表
      digitalHumanCharacterList: [],
      // 已选数字人人物
      currentDigitalHumanCharacter: {},
      form: {
        // 内容录入
        textarea: '',
        //   音频文件
        audioFile: '',

        //   分辨率
        resolution: '',
        //   背景图片
        bgPicFile: '',
        //   音色
        timbre: '',
        //   语速
        speed: 5,
        //   音量
        volume: 5,
        //   语调
        intonation: 5,
        //   水平位置
        XPosition: '0',
        //   垂直位置
        YPosition: '0',
        //   缩放比例
        scaling: '1',
        //   3D数字人像ID
        figureId: ''
      },
      // 是否正在生成视频
      isGenerating: false,
      //   生成结果
      result_content: '',

    }
  },
  methods: {
    // 生成视频
    submitForm() {
      // console.log(this.form)
      goAi().then(res => {
        if (res === 'ok') {
          let $user_info = JSON.parse(localStorage.getItem('user_info'))

          // 获取分辨率
          if (this.form.resolution === '') {
            // 未选择分辨率提示
            this.$message({
              message: '请选择分辨率',
              type: 'warning'
            });
            return
          } else {
            let resolutionArr = this.form.resolution.split('x')
            var width = Number(resolutionArr[0])
            var height = Number(resolutionArr[1])
          }

          // 判断XYZ范围是否超出
          let x = Number(this.form.XPosition)
          let y = Number(this.form.YPosition)
          let z = Number(this.form.scaling)
          if (x < -0.5 || x > 0.5) {
            this.$message({
              message: '数字人水平位置（X）超出范围',
              type: 'warning'
            });
            return
          }
          if (y < -1 || y > 1) {
            this.$message({
              message: '数字人垂直位置（Y）超出范围',
              type: 'warning'
            });
            return
          }

          if (z < 0.5 || z > 1.5) {
            this.$message({
              message: '数字人缩放比例（Z）超出范围',
              type: 'warning'
            });
            return
          }


          let data = {}

          // 没有音频文件 文本驱动
          if (this.form.audioFile === '') {

            if (this.form.timbre === '') {
              this.$message({
                type: 'warning',
                message: '请选择音色'
              })
              return
            }
            if (this.form.textarea === '') {
              this.$message({
                type: 'warning',
                message: '请录入数字人播报文本'
              })
              return
            }
            if (this.form.figureId === '') {
              this.$message({
                type: 'warning',
                message: '请选择数字人人像'
              })
              return
            }

            data = {
              mobile: $user_info.mobile,
              driveType: 1,
              data: {
                figureId: this.form.figureId,
                text_content: this.form.textarea,
                person: this.form.timbre,
                speed: this.form.speed,
                volume: this.form.volume,
                pitch: this.form.intonation,
                height: height,
                width: width,
                x: x,
                y: y,
                z: z,
                backgroundImageUrl: this.form.bgPicFile
              }
            }
          } else {
            //   有音频文件，音频驱动
            data = {
              mobile: $user_info.mobile,
              driveType: 2,
              data: {
                figureId: this.form.figureId,
                inputAudioUrl: this.form.audioFile,
                height: height,
                width: width,
                x: x,
                y: y,
                z: z,
                backgroundImageUrl: this.form.bgPicFile
              }
            }
          }
          // console.log('data', data)


          this.isGenerating = true

          this.curlPost('/szr/create_2drefine', JSON.stringify(data)).then((res2) => {
            console.log('生成内容请求结果', res2)
            if (res2.data.code === 1) {
              this.$message({
                message: '生成成功',
                type: 'success'
              });
              this.result_content = res2.data.data.output_url
            } else {
              this.$message({
                message: res2.data.msg,
                type: 'warning'
              });
            }
            this.isGenerating = false
          })


        } else {
          return this.$store.state.loginShow = true
        }
      })
    },
    // 确认选择音色
    comfirmTimbre() {
      if (this.currentTimbre.figureid) {
        this.selectTimbre = false
        this.form.timbre = this.currentTimbre.figureid
        this.$message({
          message: '已选择音色',
          type: 'success'
        });
      } else {
        this.$message({
          message: '还未选择音色',
          type: 'warning'
        });
      }
    },

    // 选择音色
    chooseTimbre(item) {
      this.checkeds = !this.checkeds
      // console.log(item)
      this.currentTimbre = item
      this.timbreList.forEach(el => {
        if (el.id !== item.id) {
          el.checked = false
        }
      })
      if (item.checked === false) {
        this.currentTimbre = {}
        this.form.timbre = ''
      }
    },
    // 确认选择数字人
    comfirmDigitalHuman() {
      if (this.currentDigitalHumanCharacter.figureid) {
        this.selectPerson = false
        this.form.figureId = this.currentDigitalHumanCharacter.figureid
        this.$message({
          message: '已选择人物',
          type: 'success'
        });
      } else {
        this.$message({
          message: '还未选择人物',
          type: 'warning'
        });
      }
    },
    // 选择数字人
    chooseDigitalHuman(item) {
      this.checkeds = !this.checkeds
      // console.log(item)
      this.currentDigitalHumanCharacter = item
      this.digitalHumanCharacterList.forEach(el => {
        if (el.id !== item.id) {
          el.checked = false
        }
      })
      if (item.checked === false) {
        this.currentDigitalHumanCharacter = {}
        this.form.figureId = ''
      }
    },
    // 获取2d数字人展示
    get3DdigitalHumans() {
      this.curlGet('/szr/show_2drefine').then((res) => {
        // console.log('3D数字人展示请求结果', res)
        if (res.data.code == 1) {
          this.digitalHumanCharacterList = res.data.data
          this.digitalHumanCharacterList.forEach((item, index) => {
            item.checked = false
          })
          console.log('数字人列表', this.digitalHumanCharacterList)
        }

      })
    },
    // 获取公共音色库
    getPublicSoundLibrary() {
      this.curlGet('/szr/show_audio').then((res) => {
        // console.log('公共音色库请求结果', res)
        if (res.data.code == 1) {
          this.timbreList = res.data.data
          this.timbreList.forEach((item, index) => {
            item.checked = false
          })
          console.log('音色列表', this.timbreList)
        }
      })
    },
    // 获取用户信息
    getUserInfo() {
      let $user_info = JSON.parse(localStorage.getItem('user_info'))
      if ($user_info) {
        this.curlGet('/api/user/info').then((res) => {
          if (res.data.code) {
            this.$util.setLocalStorage('user_info', res.data.data) // 写入local storage
            this.headers.token = res.data.data.token
          }
        })
      }
    },

    //   音频文件上传
    // 上传前的验证函数
    beforeUploadAudio(file) {
      const isAllowedType = this.checkFileType_audio(file);
      const isAllowedSize = this.checkFileSize_audio(file);
      return new Promise((resolve, reject) => {
        if (isAllowedType && isAllowedSize) {
          this.checkFileDuration(file)
              .then(() => {
                resolve(true);
              })
              .catch((error) => {
                this.$message.error(error);
                reject(false);
              });
        } else {
          if (!isAllowedType) {
            this.$message.error('仅支持 wav、mp3、wma、m4a、aac 格式的音频文件');
          }
          if (!isAllowedSize) {
            this.$message.error('文件大小不能超过 120 MB');
          }
          reject(false);
        }
      });
    },
    // 检查文件类型-音频
    checkFileType_audio(file) {
      console.log(file)
      const allowedTypes = ['audio/wav', 'audio/mp3', 'audio/mpeg', 'audio/wma', 'audio/m4a', 'audio/aac'];
      return allowedTypes.includes(file.type);
    },
    // 检查文件大小-音频
    checkFileSize_audio(file) {
      const maxSize = 120 * 1024 * 1024; // 120 MB
      return file.size <= maxSize;
    },
    // 检查文件时长-音频
    checkFileDuration(file) {
      return new Promise((resolve, reject) => {
        const audio = new Audio(URL.createObjectURL(file));
        audio.addEventListener('loadedmetadata', () => {
          const duration = audio.duration;
          if (duration <= 600) { // 10 分钟 = 600 秒
            resolve();
          } else {
            reject('音频时长不能超过 10 分钟');
          }
          URL.revokeObjectURL(audio.src);
        });
        audio.addEventListener('error', () => {
          reject('无法获取音频时长');
          URL.revokeObjectURL(audio.src);
        });
      });
    },
    // 上传成功的回调函数-音频
    uploadAudioSuccess(res, file, fileList) {
      this.$message.success('音频上传成功');
      console.log('文件上传成功', res, file, fileList);
      this.form.audioFile = res.data.fullurl
      this.audioFileName = file.name
      this.currentTimbre = {}
      this.timbreList.forEach(el => {
        el.checked = false
      })
      // console.log(this.form)
    },
    // 上传失败的回调函数-音频
    uploadAudioError(error, file, fileList) {
      this.$message.error('音频上传失败');
    },
    // 删除已上传的音频文件
    changeAudioFile() {
      this.$confirm('是否删除已上传的音频文件？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.form.audioFile = ''
        this.audioFileName = ''
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },

    //   背景图片上传
    // 上传前的验证函数
    beforeUploadBgPic(file) {
      const isAllowedType = this.checkFileType_pic(file);
      const isAllowedSize = this.checkFileSize_pic(file);
      return new Promise((resolve, reject) => {
        if (isAllowedType && isAllowedSize) {
          resolve(true);
        } else {
          if (!isAllowedType) {
            this.$message.error('仅支持 png、jpg、jpeg、bmp 格式的图片文件');
          }
          if (!isAllowedSize) {
            this.$message.error('文件大小不能超过 3 MB');
          }
          reject(false);
        }
      });
    },
    // 检查文件类型-图片
    checkFileType_pic(file) {
      const allowedTypes = ['image/png', 'image/jpg', 'image/jpeg', 'image/bmp'];
      return allowedTypes.includes(file.type);
    },
    // 检查文件大小-图片
    checkFileSize_pic(file) {
      const maxSize = 3 * 1024 * 1024; // 3 MB
      return file.size <= maxSize;
    },
    // 上传成功的回调函数-图片
    uploadBgPicSuccess(res, file, fileList) {
      this.$message.success('背景图片上传成功');
      this.form.bgPicFile = res.data.fullurl;
      this.bgPicFileName = file.name;
    },
    // 上传失败的回调函数-图片
    uploadBgPicError(error, file, fileList) {
      this.$message.error('背景图片上传失败');
    },
    // 删除已上传的背景图片文件
    changeBgPicFile() {
      this.$confirm('是否删除已上传的背景图片文件？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.form.bgPicFile = ''
        this.bgPicFileName = ''
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
  },
}
</script>

<style lang=scss scoped>
@import '@/scss/ai';
</style>